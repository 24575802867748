export const FirebaseConfig = {
	"projectId": "taxi-27474",
	"appId": "1:220471288631:web:37eab247964037ffac30b2",
	"databaseURL": "https://taxi-27474-default-rtdb.firebaseio.com",
	"storageBucket": "taxi-27474.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDPYqp2E-KtVuK0y4ioZtWmzelZB4tvoLc",
	"authDomain": "taxi-27474.firebaseapp.com",
	"messagingSenderId": "220471288631",
	"measurementId": "G-NX68QYFVGM"
};